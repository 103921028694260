
import { View, StyleSheet, Image, Text, Linking, TouchableOpacity } from "react-native"
import resizeImage from "../Components/ImagesResizing";
import CustomColors from "../commons/Colors";

const MapToGetThere = (props) => {
    const { mapURI, linkToGoogleMaps, drawBorder } = props;

    const imageWidth = 1035;
    const imageHeight = 555;
    const newSize = resizeImage(imageWidth, imageHeight)

    return (
        <View style={{ width: newSize['width'], height: newSize['height'], padding: 15 }}>
            <TouchableOpacity
                style={{
                    width: "100%", height: "100%",
                    borderColor: CustomColors.DARK_BLUE, borderWidth: 2, borderStyle: "solid",
                }}
                onPress={() => { Linking.openURL(linkToGoogleMaps) }}>
                <Image style={{ width: "100%", height: "100%" }}
                    source={mapURI}
                    resizeMode="cover"
                />
            </TouchableOpacity>
        </View>
    )
}

export default MapToGetThere;