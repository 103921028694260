import {
  View,
  StyleSheet,
  Image,
  Text,
  Linking,
  TouchableOpacity,
} from "react-native";

import CustomColors from "../commons/Colors";

const MyButton = (props) => {
  const { text, onPress } = props;

  return (
    <TouchableOpacity onPress={onPress} style={buttonStyle.container}>
      <Text style={buttonStyle.text}>{text}</Text>
    </TouchableOpacity>
  );
};

const buttonStyle = StyleSheet.create({
  container: {
    elevation: 8,
    width: 300,
    height: 80,
    alignSelf: "center",
    justifyContent: "center",
    backgroundColor: CustomColors.LIGHT_BLUE,
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 20,
  },

  text: {
    fontSize: 28,
    color: CustomColors.DARK_BLUE,
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase",
    textAlign: "center",
  },
});
export default MyButton;
