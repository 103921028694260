import { View, StyleSheet, Image, Text, Linking, TouchableOpacity } from "react-native"
import { Dimensions } from "react-native";


import CursiveText from "../Components/CursiveText"
import CustomColors from "../commons/Colors";
import TwoLinesWithIconSeparator from "../Components/TwoLinesWithIconSeparator";

const ThankYou = () => {
    return (
        <View style={{
            width: "100%",
            alignItems: "center",

            backgroundColor: CustomColors.DARK_BLUE
        }}>

            <CursiveText
                text={"Grazie di Cuore !"}
                fontSize={40}
                marginTop={50}
                color={CustomColors.PALE_WHITE}
                fontFamily={"SnellBT-Regular"}
            />

            <TwoLinesWithIconSeparator
                backgroundColor={CustomColors.PALE_WHITE}
                iconURI={require('../assets/images/reception.png')}
                marginTop={40}
            />

            <OurSignature />

        </View>
    )
}

const OurSignature = () => {
    return (
        <View style={{
            width: "100%", height: 100,
            alignItems: "center", justifyContent: "center",
            flexDirection: "row",
            marginBottom: 100,

            backgroundColor: CustomColors.DARK_BLUE
        }}>

            <Word
                flexSize={3}
                name={"Elisabetta"}
                nameIconURI={require('../assets/images/cat.png')}
            />
            <Word
                flexSize={1}
                name={"&"}
            />
            <Word
                flexSize={3}
                name={"Andrea"}
                nameIconURI={require('../assets/images/barbell.png')}
            />
        </View>
    )
}

const Word = (props) => {
    const { flexSize, name, nameIconURI } = props;
    return (
        <View style={{
            flex: flexSize, height: 200,
            alignItems: "center", justifyContent: "center",
            backgroundColor: CustomColors.DARK_BLUE
        }}>
            <CursiveText
                text={name}
                fontSize={30}
                fontWeight={"bold"}
                color={CustomColors.PALE_WHITE}
                fontFamily={"SnellBT-Regular"}
            />
            {nameIconURI &&
                <Image style={{ width: "100px", height: "100px" }}
                    source={nameIconURI}
                    resizeMode="contain"
                />}
        </View>
    )
}


export default ThankYou;
