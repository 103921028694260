import {
  View,
  StyleSheet,
  Image,
  Text,
  Linking,
  TouchableOpacity,
} from "react-native";

import CustomColors from "../../commons/Colors";
import DestinationHeader from "../../Components/HoneyMoon/Header";
import SingleDestination from "../../Components/HoneyMoon/SingleDestination";
import MyButton from "../../Components/MyButton";
import CursiveText from "../../Components/CursiveText";
import { Outlet, Link } from "react-router-dom";

import { PLACES_LIST } from "./Places/PlacesList";

const ViaggioMain = () => {
  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        backgroundColor: CustomColors.VERY_LIGHT_BLUE,
      }}
    >
      <DestinationHeader />
      <AllTheDestinations />
      <ContributeToOurHoneymoon />
      <Back />
    </View>
  );
};

const AllTheDestinations = () => {
  return PLACES_LIST.map((singleDestination, index) => (
    <SingleDestination singleDestination={singleDestination} />
  ));
};

const ContributeToOurHoneymoon = () => {
  return (
    <Link
    //to="/testPage"
    to="https://www.ilmondocapovoltoviaggi.it/liste-nozze/lista-nozze/bm1yT1NDYmFlS1JmdkpiR283ZGVxUT09"
    style={{
      borderRadius: 30,
      padding: 10,
      backgroundColor: CustomColors.LIGHT_BLUE,
      marginBottom: 20,
    }}
  >
    <CursiveText
      text={"Contribuisci al nostro Viaggio"}
      fontSize={18}
      fontWeight={"bold"}
      color={CustomColors.DARK_BLUE}
    />
  </Link>

    // <Link to="/testPage">
    //   <MyButton text={"Contribuisci al nostro Viaggio"} />
    // </Link>
  );
};

const Back = () => {
  return (
    <Link
      to="/"
      style={{
        borderRadius: 30,
        padding: 15,
        marginBottom: 10,
        backgroundColor: CustomColors.GREY,
      }}
    >
      <CursiveText
        text={"Indietro"}
        fontSize={18}
        fontWeight={"bold"}
        color={CustomColors.DARK_BLUE}
      />
    </Link>
  );
};

export default ViaggioMain;
