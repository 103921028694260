


const sendConfirmationEmail = (API_URL, data) => {
    const { nameAndSurname, email, guest, note } = data;
    return send(API_URL, nameAndSurname, email, guest, note)

}

async function send(API_URL, nameAndSurname, email, guest, note) {
    console.log("API_URL " + API_URL)
    console.log("nameAndSurname " + nameAndSurname)
    console.log("email " + email)
    console.log("guest " + guest)
    console.log("note " + note)

    const response = await fetch(API_URL, {
        method: 'POST',
        mode: "same-origin",
        credentials: "same-origin",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({
            "nameAndSurname": nameAndSurname,
            "email": email,
            "guest": guest,
            "note": note
        })
    });

    const parsedResponse = await response.json();
    console.log(parsedResponse);

    return parsedResponse;
}

export default sendConfirmationEmail;