import {
  View,
  StyleSheet,
  Image,
  Text,
  Linking,
  TouchableOpacity,
  Dimensions
} from "react-native";

import CursiveText from "../Components/CursiveText";
import CustomColors from "../commons/Colors";
import TwoLinesWithIconSeparator from "../Components/TwoLinesWithIconSeparator";

import { Outlet, Link } from "react-router-dom";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const GiftFromAttendees = () => {
  return (
    <View
      style={{
        width: "100%",
        backgroundColor: CustomColors.PALE_WHITE,
      }}
    >
      <TwoLinesWithIconSeparator
        backgroundColor={CustomColors.DARK_BLUE}
        iconURI={require("../assets/images/reception.png")}
        marginTop={40}
      />

      <HoneymoonTrip />
    </View>
  );
};

const HoneymoonTrip = () => {
  return (
    <View
      style={{
        width: "100%",
        alignItems: "center",
        padding: 10,
        // backgroundColor: "green"
        // marginTop: 40,
      }}
    >
      <CursiveText
        text={"Viaggio di Nozze"}
        fontSize={40}
        color={CustomColors.DARK_BLUE}
        fontFamily={"SnellBT-Regular"}
      />

      <CursiveText
        text={
          "La vostra presenza sarà per noi il dono più bello, ma se desiderate farci un regalo,il più gradito sarà un contributo per rendere indimenticabile il nostro viaggio"
        }
        fontSize={20}
        color={CustomColors.DARK_BLUE}
        marginTop={50}
        marginBottom={30}
      />

      <OurTrip />
    </View>
  );
};

const OurTrip = () => {
  return (
    <View style={{ width:"100%", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
      
      <FlightIcon />
      <Contribute />
      <FlightIcon />
    </View>
  );
};

const FlightIcon = () => {
  const screenArea = windowHeight * windowWidth;
  const imageAreaToScreenAreaRatio = 120;
  
  const imageSize = Math.sqrt(screenArea / imageAreaToScreenAreaRatio)
  
  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Image
        style={{ width: imageSize, height: imageSize }}
        resizeMode="center"
        source={require("../assets/images/plane.png")}
      />
    </View>
  );
};

const Contribute = () => {
  return (
    <View style={{ flex: 3 }}>
      <Link
        to="/viaggio"
        style={{
          borderRadius: 30,
          padding: 10,
          backgroundColor: CustomColors.LIGHT_BLUE,
        }}
      >
        <CursiveText
          text={"Il nostro viaggio!"}
          fontSize={18}
          fontWeight={"bold"}
          color={CustomColors.DARK_BLUE}
        />
      </Link>
    </View>
  );
};
export default GiftFromAttendees;
