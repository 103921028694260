
import { View, StyleSheet, Image, Text } from "react-native"
import CursiveText from "../Components/CursiveText"

import resizeImage from "../Components/ImagesResizing"
import CustomColors from "../commons/Colors"

const OurPictureWithText = () => {

    return (
        <View style={{
            width: "100%",
            alignItems: "center",
            backgroundColor: CustomColors.DARK_BLUE,
            // justifyContent: "flex-start",

        }}>
            <OurBigPicture />
            <ElisabettaAndAndreaText />
        </View>
    )


}

const OurBigPicture = (props) => {
    const imageWidth = 6000;
    const imageHeight = 4000;
    const newSize = resizeImage(imageWidth, imageHeight)

    return (
        <View style={{
            width: "100%",
            alignItems: "center"
            // backgroundColor: "gold"
        }}>
            <Image style={{ width: newSize['width'], height: newSize['height'], }}
                resizeMode="cover" source={require('../assets/images/main1.jpg')}
            />
        </View>
    )
}
const ElisabettaAndAndreaText = () => {
    const textToShow = "Elisabetta & Andrea"

    return (
        <View style={{
            position: "absolute",
            left: "5%",
            top: "5%",
            width: "90%",
            height: "10%",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "gold"
        }}>
            <CursiveText
                text={textToShow}
                fontSize={40}
                fontWeight={"bold"}
                color={"black"}
                fontFamily={"SnellBT-Regular"}
                shadow={true}
            />
        </View>
    )
}


export default OurPictureWithText;