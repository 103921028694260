import { View, StyleSheet, Image, Text } from "react-native"
import { Dimensions } from "react-native";
import timeUntil from "../Components/TimeUntilDate"

import CursiveText from "../Components/CursiveText"
import CustomColors from "../commons/Colors";
import TwoLinesWithIconSeparator from "../Components/TwoLinesWithIconSeparator";

const OurInvitation = () => {
    return (
        <View style={{
            width: "100%",
            alignItems: "center",
            backgroundColor: CustomColors.PALE_WHITE
        }}>
            <TwoLinesWithIconSeparator
                backgroundColor={CustomColors.DARK_BLUE}
                iconURI={require('../assets/images/invitation.png')}
                marginTop={40}
            />
            <InvitationText />
        </View>
    )
}
const InvitationText = () => {
    return (
        <View style={{
            width: "100%",
            alignItems: "center", justifyContent: "space-evenly",
            marginTop: 40,
        }}>
            <CursiveText
                text={"Siamo molto felici di invitarti ad un evento più unico che raro...."}
                fontSize={20}
                color={CustomColors.BLACK}
                marginTop={30}
                marginBottom={30}
            // fontFamily={"SnellBT-Regular"}
            />

            <CursiveText
                text={"Il Nostro Matrimonio!"}
                fontSize={38}
                fontWeight={"bold"}
                color={CustomColors.DARK_BLUE}
                fontFamily={"SnellBT-Regular"}
            />

        </View>
    )
}
export default OurInvitation;
