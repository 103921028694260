// PER PUBBLICARE: https://docs.expo.dev/distribution/publishing-websites/
// PER PAGINA BIANCA: https://github.com/facebook/create-react-app/issues/6275#issuecomment-457813277

// AVVIO SITO LOCALE: npx expo start --web

// COMPILAZIONE: npx expo export:web

// Fare upload della cartella generata web-build dentro la cartella 'www' della hosting
// HOSTING DEL SITO: https://controlpanel.register.it/welcome.html


import { BrowserRouter, Routes, Route } from "react-router-dom";

import Main from "./sections/mainPage";
import GiftFromAttendees from "./sections/GiftFromAttendees";
import ViaggioMain from "./sections/ViaggioDiNozze/ViaggioMain";
import OurIbanDetails from "./sections/ViaggioDiNozze/OurIbanDetails";
import Test from "./sections/ViaggioDiNozze/Test";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/testPage" element={<OurIbanDetails />} />
        <Route path="/viaggio" element={<ViaggioMain />} />
        <Route path="/" element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
  // return <Main />
}
