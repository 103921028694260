import { View, StyleSheet, Image, Text } from "react-native"
import { Dimensions } from "react-native";
import timeUntil from "../Components/TimeUntilDate"

import CursiveText from "../Components/CursiveText"
import CustomColors from "../commons/Colors";
import TwoLinesWithIconSeparator from "../Components/TwoLinesWithIconSeparator";

const WeWillMarryOn = () => {
    return (
        <View style={{
            width: "100%",
            alignItems: "center",
            backgroundColor: CustomColors.DARK_BLUE
        }}>

            <CursiveText
                text={"Ci sposeremo il"}
                fontSize={35}
                color={"white"}
                fontFamily={"SnellBT-Regular"}
            />

            <TwoLinesWithIconSeparator
                backgroundColor={CustomColors.PALE_WHITE}
                iconURI={require('../assets/images/rings.png')}
                marginTop={40}
            />

            <CursiveText
                text={"13 Luglio 2024"}
                fontSize={25}
                color={"white"}
                fontFamily={"SnellBT-Regular"}
            />

            <CountDownBlocks
                toYear={2024}
                toMonth={7}
                toDay={13} />

            <CursiveText
                // text={"Vi aspettiamo numerosi al nostro fianco per sostenerci con il vostro affetto nel giorno più importante della nostra Vita."}
                text={""}
                fontSize={20}
                color={"white"}
                fontFamily={"SnellBT-Regular"}
                marginTop={30}
                marginBottom={20}
            />

        </View>
    )
}



const CountDownBlocks = (props) => {
    const { toYear, toMonth, toDay } = props;
    const daysHoursMinutesLeft = timeUntil({ year: toYear, month: toMonth, day: toDay });
    return (
        <View style={{
            width: "100%",
            justifyContent: "space-evenly", alignItems: "center",
            flexDirection: "row",
            marginTop: "60px",
            // backgroundColor: "red"
        }}>
            <SingleBlock number={daysHoursMinutesLeft["daysLeft"]} label={"giorni"} />
            <SingleBlock number={daysHoursMinutesLeft["hoursLeft"]} label={"ore"} />
            <SingleBlock number={daysHoursMinutesLeft["minutesLeft"]} label={"minuti"} />
        </View>
    )
}

const SingleBlock = (props) => {
    const { number, label } = props;

    const { width, height } = Dimensions.get('window');

    let singleBlockWidth = width * 0.2
    let singleBlockHeight = singleBlockWidth;

    if (width > 1000) { // Big screen
        singleBlockWidth = width * 0.12
        singleBlockHeight = singleBlockWidth;
    }

    return (
        <View style={{
            width: singleBlockWidth, height: singleBlockHeight,
            backgroundColor: "white",
            borderWidth: 3, borderColor: "black", borderStyle: "solid",
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 9,
            },
            shadowOpacity: 0.90,
            shadowRadius: 12.35,

            elevation: 19,

            justifyContent: "center", alignItems: "center"
        }}>
            {/* <Text style={{ fontSize: 45 }}>test</Text> */}

            <CursiveText
                text={number}
                fontSize={20}
                fontWeight={"bold"}
                color={"black"}
            // fontFamily={"SnellBT-Regular"}
            />

            <CursiveText
                text={label}
                fontSize={15}
                color={"black"}
            // fontFamily={"SnellBT-Regular"}
            />
        </View>
    )
}

export default WeWillMarryOn;