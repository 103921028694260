import { View, StyleSheet, Image, Text, Dimensions } from "react-native";

import CursiveText from "../../Components/CursiveText";
import CustomColors from "../../commons/Colors";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const andreaSays =
  "Andrea: 'Voi tutti conoscete benissimo la passione che ha Elisabetta per la natura e per gli animali... soprattutto per quelli che trova per strada...'";
const elisabettaSays =
  "Elisabetta: '...e tutti voi conoscete anche la passione che ha Andrea per intrattenere rapporti internazionali....'";
const weBothSay =
  "....per questi motivi, per renderci entrambi felici, avremmo deciso di dirigerci verso il Sud Africa!";

const bettaAnimalsRoad = require("../../assets/viaggio_nozze/sudafrica/bettaAnimals.png");
const andreaRelations = require("../../assets/viaggio_nozze/sudafrica/andreaRelations.png");

export const Andrea_says = (props) => {
  return (
    <View style={textStyles.container}>
      <What_to_say textToSay={andreaSays} />
      <ImageToSpeech imageToSpeech={bettaAnimalsRoad} />
    </View>
  );
};

export const Elisabetta_says = (props) => {
  return (
    <View style={textStyles.container}>
      <ImageToSpeech imageToSpeech={andreaRelations} />
      <What_to_say textToSay={elisabettaSays} />
    </View>
  );
};

export const We_both_say = () => {
  return (
    <View style={textStyles.container}>
      <What_to_say textToSay={weBothSay} />
    </View>
  );
};

const What_to_say = (props) => {
  const { textToSay } = props;
  return (
    <View style={{ flex: 1 }}>
      <Text style={textStyles.andrea_says}>{textToSay}</Text>;
    </View>
  );
};

const ImageToSpeech = (props) => {
  const { imageToSpeech } = props;
  return (
    <View style={{ flex: 1 }}>
      <Image
        style={{ width: "100%", height: "100%" }}
        resizeMode="center"
        source={imageToSpeech}
      />
    </View>
  );
};
const textStyles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 10,
  },

  andrea_says: {
    textAlign: "justify",
    fontSize: 30,
    fontWeight: "bold",
    padding: 10,
  },

  elisabetta_says: {
    textAlign: "justify",
    fontSize: 30,
    fontWeight: "bold",
    padding: 10,
  },
});
