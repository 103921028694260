import { View, StyleSheet, Image, Text, Linking, TouchableOpacity } from "react-native"
import { Dimensions } from "react-native";

import CursiveText from "../Components/CursiveText"
import CustomColors from "../commons/Colors";
import TwoLinesWithIconSeparator from "../Components/TwoLinesWithIconSeparator";
import SinglePicture from "../Components/SinglePicture";
import MapToGetThere from "../Components/MapToGetThere";

const TheChurch = () => {
    return (
        <View style={{
            width: "100%",
            alignItems: "center",
            backgroundColor: CustomColors.DARK_BLUE
        }}>
            <TwoLinesWithIconSeparator
                backgroundColor={CustomColors.PALE_WHITE}
                iconURI={require('../assets/images/church.png')}
                marginTop={40}
            />

            <ChurchDetails />

        </View>
    )
}



const ChurchDetails = () => {
    return (
        <View style={{
            width: "100%",
            alignItems: "center",
            // backgroundColor: "green"
            // marginTop: 40,
        }}>
            <CursiveText
                text={"La nostra Chiesa"}
                fontSize={40}
                color={CustomColors.PALE_WHITE}
                fontFamily={"SnellBT-Regular"}
            />

            <CursiveText
                text={"Chiesa di Santa Maria Maggiore"}
                fontSize={20}
                textDecorationLine={"underline"}
                color={CustomColors.PALE_WHITE}
                marginTop={30}
            />
            <CursiveText
                text={"Piazza della Chiesa 6, 16016 Cogoleto Italia."}
                fontSize={20}
                color={CustomColors.PALE_WHITE}
                marginBottom={30}
            />

            <ChuchPictures />

            <CursiveText
                text={"Inizio cerimonia: ore 15:00"}
                fontSize={30}
                color={CustomColors.PALE_WHITE}
                fontFamily={"SnellBT-Regular"}
            />

            <MapToGetThere
                mapURI={require('../assets/images/ChiesaMap.png')}
                linkToGoogleMaps={'https://maps.app.goo.gl/2KwsAyjL5YzYN8x67'}
            />


        </View>
    )
}

const ChuchPictures = () => {
    const { width, height } = Dimensions.get('window');

    let singlePictureWidth = width * 0.3
    let singlePictureHeight = singlePictureWidth;

    if (width > 1000) { // Big screen
        singlePictureWidth = width * 0.2
        singlePictureHeight = singlePictureWidth;
    }

    return (
        <View style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,

            flexWrap: "wrap",
        }}>
            <SinglePicture
                width={singlePictureWidth}
                height={singlePictureHeight}
                link={'https://it.wikipedia.org/wiki/Chiesa_di_Santa_Maria_Maggiore_(Cogoleto)'}
                pictureURI={require('../assets/images/ChiesaCogoleto1.png')}
            />
            <SinglePicture
                width={singlePictureWidth}
                height={singlePictureHeight}
                link={'https://it.wikipedia.org/wiki/Chiesa_di_Santa_Maria_Maggiore_(Cogoleto)'}
                pictureURI={require('../assets/images/ChiesaCogoleto2.png')}
            />
            <SinglePicture
                width={singlePictureWidth}
                height={singlePictureHeight}
                link={'https://it.wikipedia.org/wiki/Chiesa_di_Santa_Maria_Maggiore_(Cogoleto)'}
                pictureURI={require('../assets/images/ChiesaCogoleto3.png')}
            />


        </View>
    )
}

export default TheChurch;
