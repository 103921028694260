import { View, StyleSheet, Image, Text } from "react-native"
import { useFonts } from 'expo-font';
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

const CursiveText = (props) => {

    const [fontsLoaded] = useFonts({
        'SnellBT-Regular': require('../assets/fonts/SnellBT-Regular.otf'),
    });

    const { text, fontSize, fontWeight, textDecorationLine, color, fontFamily, marginTop, marginBottom } = props;
    const { shadow } = props;
    return (
        <View style={{
            marginTop: marginTop ? marginTop : 0,
            marginBottom: marginBottom ? marginBottom : 0
        }}>
            <Text style={{
                fontSize: RFValue(fontSize),
                color: color,
                fontFamily: fontFamily ? fontFamily : "Times",
                fontWeight: fontWeight ? fontWeight : "normal",
                textDecorationLine: textDecorationLine ? textDecorationLine : "none",
                fontStyle: "italic",
                textAlign: "center",

                textShadowColor: shadow ? 'rgba(0, 0, 0, 0.75)' : 'none',
                textShadowOffset: shadow ? { width: -1, height: 1 } : { width: 0, height: 0 },
                textShadowRadius: shadow ? 10 : 0

            }}>{text}</Text>
        </View>

    )
}

export default CursiveText;