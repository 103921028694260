import { View, StyleSheet, Image, Text, Dimensions } from "react-native";

import CursiveText from "../../Components/CursiveText";
import CustomColors from "../../commons/Colors";

import { Andrea_says, Elisabetta_says, We_both_say } from "./We_says";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const DestinationHeader = () => {
  return (
    <View>
      <MainTitle />
      {/*<Quote />
      <Andrea_says />
      <Elisabetta_says />
      <We_both_say/>
      <OurTrip/> */}
    </View>
  );
};

const MainTitle = () => {
  return (
    <View style={{ marginBottom: 10, flexDirection: "row" }}>
      <Flag DARK_BLUE/>
      <CursiveText
        text={"Sudafrica"}
        fontSize={40}
        fontWeight={"bold"}
        color={CustomColors.DARK_BLUE}
        fontFamily={"SnellBT-Regular"}
      />
      <Flag />
    </View>
  );
};


const OurTrip = () => {
  return(
    <CursiveText
        text={"Il nostro Viaggio"}
        fontSize={25}
        fontWeight={"bold"}
        color={CustomColors.DARK_BLUE}
        fontFamily={"SnellBT-Regular"}
      />
  );
}
const Quote = () => {
  return (
    <View style={{ padding: 10 }}>
      <CursiveText
        text={"Il nostro viaggio"}
        fontSize={25}
        fontWeight={"bold"}
        color={CustomColors.BLACK}
        fontFamily={"SnellBT-Regular"}
      />
    </View>
  );
};

const Flag = () => {
  const screenArea = windowHeight * windowWidth;
  const imageAreaToScreenAreaRatio = 200;

  const imageSize = Math.sqrt(screenArea / imageAreaToScreenAreaRatio);

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        marginRight: "30px",
        marginLeft: "30px",
      }}
    >
      <Image
        style={{ width: imageSize, height: imageSize }}
        resizeMode="center"
        source={require("../../assets/viaggio_nozze/sudafrica/flag.png")}
      />
    </View>
  );
};

export default DestinationHeader;
