
// NUOVA ZELANDA
// export const aucklandPic = require("../../../assets/viaggio_nozze/nuova_zelanda/auckland.png");
// export const aucklandTitle = "Auckland"
// export const aucklandDescription = "La città, comunemente soprannominata Città delle vele, è intitolata a George Eden, I conte di Auckland, primo Lord grand'ammiraglio e governatore generale dell'India. Sorta sull'omonimo istmo, Auckland è una delle pochissime città al mondo ad avere porti su due bacini marittimi distinti, ed è il più importante porto della Nuova Zelanda grazie alla sua posizione sulle rotte transpacifiche.";
// export const aucklandLink = "https://www.aucklandnz.com/";


// export const rotoruaPic = require("../../../assets/viaggio_nozze/nuova_zelanda/rotorua.png");
// export const rotoruaTitle = "Rotorua"
// export const rotoruaDescription = "È situata nella regione della Baia dell'Abbondanza, sulla sponda meridionale del lago Rotorua, circa 280 km a sud est di Auckland. La città è molto popolare per le attività legate alla geotermia, sia per quanto riguarda le strutture termali sia i geyser (il più famoso è il Pohutu, il cui getto arriva fino a 20 metri d'altezza).";
// export const rotoruaLink = "https://www.rotoruanz.com/";

// export const lakeTekapoPic = require("../../../assets/viaggio_nozze/nuova_zelanda/tekapo.png");
// export const lakeTekapoTitle = "Logo Tekapo"
// export const lakeTekapoDescription = "Il lago Tekapo è un lago morenico della Nuova Zelanda, si trova nel Distretto di Mackenzie della regione di Canterbury, nell'Isola del Sud.";
// export const lakeTekapoLink = "https://www.goaustralia.it/lago-tekapo-uno-dei-piu-importanti-belli-della-nuova-zelanda/";


// SUDAFRICA
export const capeTown_Pic = require("../../../assets/viaggio_nozze/sudafrica/capetown.jpg");
export const capeTown_Title = "Cape Town"
export const capeTown_Dates = "27-30 Agosto 2024"
export const capeTown_Description = "Siamo sull’estrema punta meridionale dell’Africa, immediatamente a sud del Tropico del Capricorno, nella provincia di Western Cape, immersi nella natura e in uno scenario unico al mondo incastonato tra la Table Mountain e la splendida baia. Il centro della città, City Bowl, si stende infatti in una depressione in mezzo a numerosi rilievi, tra cui il più noto è il caratteristico Tafelberg, Table Mountain, che sovrasta la città. Simbolo della città, la Table Mountain, oggi parco nazionale, è alta oltre mille metri e la cima può essere raggiunta con una funivia panoramica, la Cableway. Dall’alto si può ammirare una vista indimenticabile. E poi l’affaccio sull’Oceano Atlantico, sulla Table Bay in cui si trova l’isola di Robben Island, l’isola delle foche. Patrimonio dell’UNESCO dal 1999, l’isola fu la prigione di molti membri dell’African National Congress tra cui lo stesso Mandela. I dintorni di Città del Capo offrono moltissime possibilità di escursioni: oltre alle bellissime spiagge di sabbia bianca a breve distanza dal centro cittadino, da non perdere una visita del Capo di Buona Speranza, della raffinata regione dei vigneti, caratterizzata dagli storici edifici in stile “Cape Dutch”, dove si producono i deliziosi vini sudafricani.";
export const capeTown_Link = "https://www.capetown.gov.za/";

export const hermanus_Pic = require("../../../assets/viaggio_nozze/sudafrica/hermanus.jpg");
export const hermanus_Dates = "30-31 Agosto 2024"
export const hermanus_Title = "Hermanus"
export const hermanus_Description = "Fino a qualche decennio fa tranquillo villaggio di pescatori, negli ultimi anni Hermanus è diventata una cittadina molto vivace, con un gran numero di alberghi, ristoranti e negozi. Situata a 122 km da Cape Town, questa cittadina rappresenta la meta ideale per un’escursione in giornata ed è diventata estremamente popolare tra i sudafricani come luogo di villeggiatura. La rapida crescita verificatasi nel corso degli ultimi anni si spiega non tanto con le sue belle spiagge, quanto con la presenza nella Walker Bay - da giugno a dicembre - di un gran numero di balene franche australi, al punto che molti considerano Hermanus la cittadina migliore del mondo per dedicarsi al whale-watching dalla terraferma. Sebbene la città si estenda su una lunga strada principale, il centro può essere visitato facilmente a piedi. Nei dintorni di Hermanus troverete un magnifico sentiero a strapiombo sul mare percorribile a piedi e diversi altri itinerari che si inoltrano sulle vicine colline ricoperte di fynbos; inoltre è possibile dedicarsi alle degustazioni di vino e nel mese di settembre si svolge l’Hermanus Whale Festival.";
export const hermanus_Link = "https://www.hermanus.co.za/";

export const knysna_Pic = require("../../../assets/viaggio_nozze/sudafrica/knysna.jpg");
export const knysna_Dates = "31 Agosto-1 Settembre 2024"
export const knysna_Title = "Knysna"
export const knysna_Description = "Knysna è situata lungo la famosa e bellissima Garden Route del Sudafrica, nella provincia del Capo occidentale. Questa cittadina vanta un incredibile festival degli struzzi annuale, persone amichevoli, foreste magiche e i panorami più mozzafiato da Leisure Isle fino a Knysna Heads. Una visita a Knysna è il mix perfetto tra avventura e relax. La città boschiva di Knysna, che è cresciuta intorno a una vasta laguna che ha lo stesso nome, è un paradiso verdeggiante di foreste indigene rigogliose, tranquilli laghi azzurri e spiagge dorate baciate dal sole. È situata sulle sponde di una laguna che è una riserva marina protetta e che ospita, tra gli altri, straordinari cavallucci marini e oltre 200 specie di pesci.";
export const knysna_Link = "https://www.whaletrail.co.za/south-africa-info-knysna.html";


export const addo_Pic = require("../../../assets/viaggio_nozze/sudafrica/addo.jpg");
export const addo_Dates = "1-3 Settembre 2024"
export const addo_Title = "Addo Elephant Park"
export const addo_Description = "Il parco nazionale Addo Elephant, terzo del paese per estensione (misura complessivamente circa 3.500 chilometri quadrati, le dimensioni della regione Valle d’Aosta) è forse il più splendido esempio dell’impegno che in Sudafrica viene profuso da decenni per recuperare e proteggere il patrimonio faunistico che si andava perdendo a causa della caccia, dello sviluppo e degli insediamenti umani. L’elefante africano, in origine diffusissimo nella zona costiera meridionale del paese, era pressoché sparito già nel 1930 quando in tutta la costa fra Cape Town e Durban ne rimanevano ormai una decina di esemplari; fu dal 1940 che si iniziò a raggruppare questi pochi esemplari in una piccola area recintata, favorendone la riproduzione ed ampliando pian piano le dimensioni della riserva che oggi si estende su un vasto territorio completamente recintato e privo di insediamenti umani (tranne qualche lodge e campeggio). Con l’aumento delle dimensioni del parco sono pian piano ritornate a viverci tutte le principali specie africane, alcune tornate spontaneamente altre reinserite recuperando esemplari da situazioni disagiate. Oggi il parco ha una popolazione di parecchie centinaia di elefanti e bufali, molti leoni e leopardi, rinoceronti neri e giraffe, oltre ovviamente a tante tipologie di impala, antilopi, uccelli ed anche le non comuni iene maculate.";
export const addo_Link = "https://www.sanparks.org/parks/addo-elephant";

export const kruger_Pic = require("../../../assets/viaggio_nozze/sudafrica/kruger.jpg");
export const kruger_Dates = "4-5 Settembre 2024"
export const kruger_Title = "Kruger National Park"
export const kruger_Description = "Uno dei simboli del paese, il Kruger National Park è uno dei parchi principali del Sudafrica. Molto esteso, di forma stretta e allungata (350 km in lunghezza e 65 in larghezza), è prevalentemente formato da pianure e colline lievemente ondulate, anche se a oriente, lungo il confine con il Mozambico, si erge una catena montuosa. All’interno del parco si possono percorrere numerose strade asfaltate, una delle quali lo attraversa longitudinalmente. A questa rete stradale si deve poi aggiungere l’insieme di strade secondarie, molte delle quali in ghiaia, quasi tutte in buone condizioni. Oggi il Kruger è anche un validissimo centro di ricerca e di conservazione del patrimonio naturale, che in larga parte si autofinanzia.I parchi nazionali del Sudafrica rappresentano ovviamente una delle principali attrattive di questo vasto Paese. Tutti i parchi e le riserve beneficiano di infrastrutture di alto livello e all’avanguardia. Alcuni dei parchi più estesi propongono escursioni naturalistiche guidate da ranger. Anche se non è sempre necessaria la presenza di un accompagnatore, è comunque certo che muovendosi con l’ausilio di una guida esperta è possibile vedere e imparare molto di più che non da soli, oltretutto in totale sicurezza. Il Sudafrica è probabilmente il Paese che offre le migliori opportunità di osservare i cosiddetti “big five” (rinoceronti, bufali, leoni, leopardi ed elefanti).";
export const kruger_Link = "https://www.sanparks.org/parks/kruger";

export const mpumalanga_Pic = require("../../../assets/viaggio_nozze/sudafrica/mpumalanga.jpg");
export const mpumalanga_Dates = "5-6 Settembre 2024"
export const mpumalanga_Title = "Mpumalanga, Panorama Route"
export const mpumalanga_Description = "La Panorama Route, da est a ovest nella provincia di Mpumalanga in Sudafrica, conduce attraverso la catena montuosa del Drakensberg settentrionale, che comprende le vette più alte nell’Africa meridionale. Il percorso consente viste mozzafiato nella parte nord-orientale della Grande Scarpata, lussureggiante di foresta selvaggia, fino a declinare bruscamente molto più in basso dove si allargano le verdi pianure del Lowveld. All’inizio della Panorama Route si trovano le cittadine di Lydenberg e White River. Altri piccoli centri sono Sabie, Graskop, Orighstad, Hoedspruit e Hazyview. La cittadina più famosa è Pilgrim’s Rest, una specie di museo vivente dedicato all’epopea della corsa all’oro. Per gli appassionati di sport avventurosi non c’è che l’imbarazzo della scelta: scalate, discese a corda doppia, canyoning, esplorazione di caverne, rafting, bungee jumping, percorsi con fuoristrada, equitazione, motociclismo, quad e un’infinità di sentieri di trekking.";
export const mpumalanga_Link = "https://www.africamojatours.com/the-beauty-of-mpumalanga-province-in-south-africa-2/";

export const karongwe_Pic = require("../../../assets/viaggio_nozze/sudafrica/karongwe.jpg");
export const karongwe_Dates = "7-8 Settembre 2024"
export const karongwe_Title = "Karongwe, Riserva privata"
export const karongwe_Description = "Facilmente raggiungibile da Hoedspruit, la Karongwe Private Game Reserve ospita svariati numerosi lodge e safari camp per tutti i gusti e dall'eccellente rapporto qualità prezzo. Tra questi: Chisomo Safari Camp (grazioso e accogliente campo tendato di lusso in stile East African e il 'fratello minore' Chisomo Bush Lodge, Karongwe River Lodge (dallo stile forse demodè ma dalla posizione eccellente di fronte al letto di un fiume), Shiduli Game Lodge (ideale per le famiglie), Kuname River Lodge (consigliato alle coppie in viaggio di nozze) e l'esclusivo Kuname Manor House.";
export const karongwe_Link = "https://chisomo.karongweportfolio.com/";

export const victoria_Pic = require("../../../assets/viaggio_nozze/sudafrica/victoria.jpg");
export const victoria_Dates = "9-11 Settembre 2024"
export const victoria_Title = "Victoria Falls"
export const victoria_Description = "Le cascate Vittoria (inglese: Victoria Falls; lozi: Mosi-oa-Tunya, 'Il fumo che tuona'; tonga: Shungu Namutitima, 'Acqua ribollente') sono cascate che si trovano in Africa meridionale, lungo il corso del fiume Zambesi (o Zambezi), che in questo punto costituisce il confine geografico tra Zambia e Zimbabwe. Il fronte delle cascate è lungo più di un chilometro e mezzo, mentre la loro altezza media è di 128 metri. La loro particolarità è dovuta alla conformazione del luogo, che permette di ammirare tutto il fronte della cascata da un singolo punto.";
export const victoria_Link = "https://www.zambiatourism.com/destinations/waterfalls/victoria-falls/";
