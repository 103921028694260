import { Dimensions, PixelRatio } from "react-native";


const resizeImage = (originalImageWidth, originalImageHeight) => {
    const height = Dimensions.get("window").height;
    const width = Dimensions.get("window").width;

    // alert("width " + width + " height " + height)
    const heightWidthRatio = originalImageHeight / originalImageWidth

    let newImageWidth = originalImageWidth;
    if (originalImageWidth > width)
        newImageWidth = width

    // alert(newImageWidth);
    const newImageHeight = newImageWidth * heightWidthRatio

    return ({ width: newImageWidth, height: newImageHeight })
}

export default resizeImage;