
import { capeTown_Pic, capeTown_Title, capeTown_Dates, capeTown_Description, capeTown_Link } from "./ThePlaces"
import { hermanus_Pic, hermanus_Title, hermanus_Dates, hermanus_Description, hermanus_Link } from "./ThePlaces"
import { knysna_Pic, knysna_Title, knysna_Dates, knysna_Description, knysna_Link } from "./ThePlaces"
import { addo_Pic, addo_Title, addo_Dates, addo_Description, addo_Link } from "./ThePlaces"
import { kruger_Pic, kruger_Title, kruger_Dates, kruger_Description, kruger_Link } from "./ThePlaces"
import { mpumalanga_Pic, mpumalanga_Title, mpumalanga_Dates, mpumalanga_Description, mpumalanga_Link } from "./ThePlaces"
import { karongwe_Pic, karongwe_Title, karongwe_Dates, karongwe_Description, karongwe_Link } from "./ThePlaces"
import { victoria_Pic, victoria_Title, victoria_Dates, victoria_Description, victoria_Link } from "./ThePlaces"

export const PLACES_LIST = [
    {id:1, linkToImage: capeTown_Pic, title: capeTown_Title, dates:capeTown_Dates , description:capeTown_Description, linkToWebPage: capeTown_Link},
    {id:4, linkToImage: hermanus_Pic, title: hermanus_Title, dates: hermanus_Dates, description:hermanus_Description, linkToWebPage: hermanus_Link},
    {id:5, linkToImage: knysna_Pic, title: knysna_Title, dates: knysna_Dates, description: knysna_Description, linkToWebPage: knysna_Link},
    {id:6, linkToImage: addo_Pic, title: addo_Title, dates:addo_Dates , description: addo_Description, linkToWebPage: addo_Link},
    {id:7, linkToImage: kruger_Pic, title: kruger_Title, dates:kruger_Dates , description: kruger_Description, linkToWebPage: kruger_Link},
    {id:8, linkToImage: mpumalanga_Pic, title: mpumalanga_Title, dates: mpumalanga_Dates, description: mpumalanga_Description, linkToWebPage: mpumalanga_Link},
    {id:9, linkToImage: karongwe_Pic, title: karongwe_Title, dates: karongwe_Dates , description: karongwe_Description, linkToWebPage: karongwe_Link},
    {id:10, linkToImage: victoria_Pic, title: victoria_Title, dates: victoria_Dates, description: victoria_Description, linkToWebPage: victoria_Link},
]
