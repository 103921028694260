import {
  View,
  ScrollView,
  StyleSheet,
  Image,
  Text,
  Linking,
  TouchableOpacity,
} from "react-native";
import CustomColors from "../../commons/Colors";

const SingleDestination = (props) => {
  const { singleDestination } = props;
  const { linkToImage, title, dates, description, linkToWebPage } =
    singleDestination;

  return (
    <View style={styles.mainContainer}>
      <Text style={styles.text}>{dates}</Text>
      <View style={styles.singleDestinationContainer}>
        <SingleDestinationImage linkToImage={linkToImage} />
        <SingleDestinationText
          title={title}
          dates={""}
          description={description}
          linkToWebPage={linkToWebPage}
        />
      </View>
    </View>
  );
};

const SingleDestinationImage = (props) => {
  const { linkToImage } = props;
  return (
    <View style={{ flex: 3 }}>
      <Image
        style={{
          width: "100%",
          height: "100%",
          borderColor: "black",
          borderWidth: 2,
        }}
        source={linkToImage}
        resizeMode="cover"
      />
    </View>
  );
};

const SingleDestinationText = (props) => {
  const { title, dates, description, linkToWebPage } = props;
  return (
    <View style={{ flex: 5, backgroundColor: CustomColors.DARK_BLUE }}>
      <SingleDestination_Title title={title} dates={dates} />
      <SingleDestination_Description description={description} />
      <SingleDestination_Link linkToWebPage={linkToWebPage} />
    </View>
  );
};

const SingleDestination_Title = (props) => {
  const { title, dates } = props;
  return (
    <View
      style={{
        flex: 2,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          fontSize: 20,
          fontWeight: "bold",
          color: CustomColors.PALE_WHITE,
        }}
      >
        {/* {title + ", " + dates} */}
        {title}
      </Text>
    </View>
  );
};

const SingleDestination_Description = (props) => {
  const { description } = props;
  return (
    <ScrollView
      style={{
        flex: 4,
        flexWrap: "wrap",
        padding: 5,
        backgroundColor: CustomColors.GREY,
      }}
    >
      <Text style={{ fontSize: 16, alignSelf: "center", textAlign: "justify" }}>
        {description}
      </Text>
    </ScrollView>
  );
};

const SingleDestination_Link = (props) => {
  const { linkToWebPage } = props;
  return (
    <TouchableOpacity
      style={{
        backgroundColor: CustomColors.VERY_LIGHT_BLUE,
        padding: 3,
        justifyContent: "center",
        alignItems: "center",
      }}
      onPress={() => {
        Linking.openURL(linkToWebPage);
      }}
    >
      <Text style={{ color: CustomColors.DARK_BLUE }}>Scopri di più!</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  mainContainer: {flex: 1, alignItems: "center"},
  singleDestinationContainer: {
    width: "90%",
    height: 250,
    flexDirection: "row",
    borderColor: "black",
    borderWidth: 3,
    borderRadius: 30,
    overflow: "hidden",
    marginBottom: 50,
  },

  text: {
    fontSize: 20,
    alignSelf: "flex-start",
    marginLeft: 35,
    fontWeight: "bold",
    color: CustomColors.DARK_BLUE
  },
});

export default SingleDestination;
