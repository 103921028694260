import { View } from "react-native"


import OurPictureWithText from "./OurPictureWithText";
import WeWillMarryOn from "./WeWillMarryOn";
import OurInvitation from "./OurInvitation";
import TheChurch from "./TheChurch";
import TheReception from "./TheReception";
import Confirmation from "./Confirmation";
import GiftFromAttendees from "./GiftFromAttendees";
import ThankYou from "./ThankYou";

const Main = () => {

    return (
        <View style={{ flex: 1 }}>
            <OurPictureWithText />
            <WeWillMarryOn />
            <OurInvitation />
            <TheChurch />
            <TheReception />
            <Confirmation />
            <GiftFromAttendees />
            <ThankYou />
        </View>
    )
}

export default Main;