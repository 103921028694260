import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  TextInput,
  Pressable,
  Linking
} from "react-native";
import { useEffect, useState } from "react";
import { Dimensions, PixelRatio } from "react-native";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

import CursiveText from "../Components/CursiveText";
import CustomColors from "../commons/Colors";
import TwoLinesWithIconSeparator from "../Components/TwoLinesWithIconSeparator";
import sendConfirmationEmail from "../Components/MyAPI";
import SendEmailConfirmationBox from "../Components/sendEmailConfirmationBox";

const Confirmation = () => {
  const [showSendEmailConfirmationBox, setShowSendEmailConfirmationBox] =
    useState(false);
  const [confirmationEmailAck, setConfirmationEmailAck] = useState(false);

  const [nameAndSurname, setNameAndSurname] = useState("");
  const [email, setEmail] = useState("");
  const [guest, setGuest] = useState("");
  const [note, setNote] = useState("");

  return (
    <View style={{ flex: 1, backgroundColor: CustomColors.DARK_BLUE }}>
      <TwoLinesWithIconSeparator
        backgroundColor={CustomColors.PALE_WHITE}
        iconURI={require("../assets/images/confirmation.png")}
        marginTop={40}
      />

      <CursiveText
        text={"Conferma la tua presenza tramite WhatsApp"}
        fontSize={38}
        color={CustomColors.PALE_WHITE}
        fontFamily={"SnellBT-Regular"}
      />

      <Andrea_Elisabetta_MobileNumbers />
    </View>
  );
};

const Andrea_Elisabetta_MobileNumbers = () => {
  return (
    <View
      style={{ flex: 1, flexDirection: "row", marginTop: 10, marginBottom: 15 }}
    >
      <View style={{ flex: 1, alignItems: "center" }}>
        <CursiveText
          text={"Andrea"}
          fontSize={26}
          fontWeight={"bold"}
          color={CustomColors.PALE_WHITE}
        />
        
          <CursiveText
            text={"3666094324"}
            fontSize={20}
            color={CustomColors.PALE_WHITE}
          />
        
      </View>

      <View style={{ flex: 1, alignItems: "center" }}>
        <CursiveText
          text={"Elisabetta"}
          fontSize={26}
          fontWeight={"bold"}
          color={CustomColors.PALE_WHITE}
        />
        <CursiveText
          text={"3474623826"}
          fontSize={20}
          color={CustomColors.PALE_WHITE}
        />
      </View>
    </View>
  );
};

// return (
//     <View style={{
//         width: "100%",
//         marginBottom: 30,
//         backgroundColor: CustomColors.DARK_BLUE
//     }}>

//         <SendEmailConfirmationBox
//             showSendEmailConfirmationBox={showSendEmailConfirmationBox}
//             setShowSendEmailConfirmationBox={setShowSendEmailConfirmationBox}
//             confirmationEmailAck={confirmationEmailAck}
//             setConfirmationEmailAck={setConfirmationEmailAck}
//         />

//         <TwoLinesWithIconSeparator
//             backgroundColor={CustomColors.PALE_WHITE}
//             iconURI={require('../assets/images/confirmation.png')}
//             marginTop={40}
//         />

//   <CursiveText
//       text={"Conferma la tua presenza"}
//       fontSize={40}
//       color={CustomColors.PALE_WHITE}
//       fontFamily={"SnellBT-Regular"}
//   />

//         <MailingForm
//             setNameAndSurname={setNameAndSurname}
//             setEmail={setEmail}
//             setGuest={setGuest}
//             setNote={setNote}
//         />

//         <Send
//             nameAndSurname={nameAndSurname}
//             email={email}
//             guest={guest}
//             note={note}

//             setShowSendEmailConfirmationBox={setShowSendEmailConfirmationBox}
//             setConfirmationEmailAck={setConfirmationEmailAck}
//         />
//     </View>
// )

const MailingForm = (props) => {
  const { setNameAndSurname, setEmail, setGuest, setNote } = props;

  return (
    <View
      style={{
        width: "100%",
        padding: 10,
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: "row",
        flexWrap: "wrap",

        marginTop: 30,
        marginBottom: 30,
        backgroundColor: CustomColors.DARK_BLUE,
      }}
    >
      <SingleTextBox
        label={"Nome e Cognome*"}
        onChangeText={setNameAndSurname}
      />

      <SingleTextBox label={"Indirizzo email*"} onChangeText={setEmail} />

      <SingleTextBox label={"Accompagnatori"} onChangeText={setGuest} />

      <SingleTextBox label={"Note"} onChangeText={setNote} />
    </View>
  );
};

const SingleTextBox = (props) => {
  const { label, onChangeText } = props;
  const width = Dimensions.get("window").width;

  let textBoxHeight = "50%";
  if (width > 1000) textBoxHeight = "55%";

  return (
    <View
      style={{
        width: "45%",
        height: textBoxHeight,
        marginBottom: 20,
        marginRight: 10,
        // backgroundColor: "yellow"
      }}
    >
      <Text
        style={{
          fontSize: RFValue(12),
          color: CustomColors.PALE_WHITE,
          marginBottom: 5,
        }}
      >
        {" " + label}
      </Text>

      <TextInput
        style={style.textBox}
        placeholder="  Scrivi qui..."
        placeholderTextColor={CustomColors.GREY}
        onChangeText={onChangeText}
      />
    </View>
  );
};

const Send = (props) => {
  const { nameAndSurname, email, guest, note } = props;
  const { setShowSendEmailConfirmationBox, setConfirmationEmailAck } = props;
  return (
    <View
      style={{
        width: "100%",
        height: "15%",
        marginBottom: 50,

        alignItems: "center",
        justifyContent: "center",
        backgroundColor: CustomColors.DARK_BLUE,
      }}
    >
      <Pressable
        style={style.pressable}
        onPress={() => {
          if (areTheFieldsOK(nameAndSurname, email)) {
            setShowSendEmailConfirmationBox(true);
            sendTheMessage(
              nameAndSurname,
              email,
              guest,
              note,
              setConfirmationEmailAck
            );
          }
        }}
      >
        <Text
          style={{
            fontSize: RFValue(16),
            fontWeight: "bold",
          }}
        >
          Invia
        </Text>
      </Pressable>
    </View>
  );
};

const areTheFieldsOK = (nameAndSurname, email) => {
  // console.log("nameAndSurname " + nameAndSurname)
  // console.log("email " + email)

  if (nameAndSurname.length == 0) {
    alert("Il campo Nome e Cognome non può essere vuoto!");
    return false;
  }

  if (email.length == 0 || !validEmail(email)) {
    alert("Il campo Email non può essere vuoto oppure è mal formattato!");
    return false;
  }
  return true;
};

// https://www.abstractapi.com/guides/email-address-format-validation
const validEmail = (email) => {
  const regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
  return regex.test(email);
};

const sendTheMessage = async (
  nameAndSurname,
  email,
  guest,
  note,
  setConfirmationEmailAck
) => {
  const PHP_PAGE__SEND_EMAIL__URL = "../PHP/Mail/sendMail.php";
  const data = {
    nameAndSurname: nameAndSurname,
    email: email,
    guest: guest,
    note: note,
  };
  const res = await sendConfirmationEmail(PHP_PAGE__SEND_EMAIL__URL, data);

  setConfirmationEmailAck(true);
  console.log("res " + res);
};

const style = StyleSheet.create({
  pressable: {
    width: "20%",
    height: "70%",
    alignItems: "center",
    justifyContent: "center",
    borderColor: CustomColors.DARK_BLUE,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 10,

    shadowColor: "#FFF",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.7,
    shadowRadius: 2,
    elevation: 5,

    backgroundColor: CustomColors.PALE_WHITE,
  },

  textBox: {
    width: "100%",
    height: "90%",
    borderWidth: 2,
    borderRadius: 10,

    fontSize: RFValue(12),

    shadowColor: "#FFF",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.7,
    shadowRadius: 2,
    elevation: 5,

    backgroundColor: CustomColors.PALE_WHITE,
  },
});

export default Confirmation;
