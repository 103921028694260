import { View, StyleSheet, Image, Text } from "react-native"

const TwoLinesWithIconSeparator = (props) => {
    const { backgroundColor, iconURI } = props;
    const { marginTop } = props;

    return (
        <View style={{
            width: "100%", height: "100px",
            alignItems: "center", justifyContent: "space-evenly",
            flexDirection: "row",
            marginTop: marginTop ? marginTop : 0,
        }}>
            <View style={{ width: "30%", height: "3px", backgroundColor: backgroundColor }}></View>
            <Image style={{ width: "60px", height: "60px" }} source={iconURI} />
            <View style={{ width: "30%", height: "3px", backgroundColor: backgroundColor }}></View>
        </View>
    )
}

export default TwoLinesWithIconSeparator;