import { View, StyleSheet, Image, TouchableOpacity, Linking } from "react-native"

const SinglePicture = (props) => {
    const { width, height, pictureURI, link } = props;

    return (
        <TouchableOpacity style={{
            width: width, height: height,
        }}
            onPress={() => Linking.openURL(link)}>
            <Image style={{ width: "100%", height: "100%" }}
                resizeMode="contain"
                source={pictureURI} />
        </TouchableOpacity>
    )
}

export default SinglePicture;