import { View, StyleSheet, Image, Text, Linking, TouchableOpacity } from "react-native"
import { Dimensions } from "react-native";


import CursiveText from "../Components/CursiveText"
import CustomColors from "../commons/Colors";
import TwoLinesWithIconSeparator from "../Components/TwoLinesWithIconSeparator";
import SinglePicture from "../Components/SinglePicture";
import MapToGetThere from "../Components/MapToGetThere";

const TheReception = () => {
    return (
        <View style={{
            width: "100%",
            backgroundColor: CustomColors.PALE_WHITE
        }}>

            <TwoLinesWithIconSeparator
                backgroundColor={CustomColors.DARK_BLUE}
                iconURI={require('../assets/images/reception.png')}
                marginTop={40}
            />

            <ReceptionDetails />

        </View>
    )
}

const ReceptionDetails = () => {
    return (
        <View style={{
            width: "100%",
            alignItems: "center",
            // backgroundColor: "green"
            // marginTop: 40,
        }}>
            <CursiveText
                text={"Il Ricevimento"}
                fontSize={40}
                color={CustomColors.DARK_BLUE}
                fontFamily={"SnellBT-Regular"}
            />

            <TouchableOpacity
                onPress={() => Linking.openURL('https://www.villafaraggiana.it/')}>
                <CursiveText
                    text={"Villa Faraggiana (Official website)"}
                    fontSize={20}
                    textDecorationLine={"underline"}
                    color={CustomColors.DARK_BLUE}
                    marginTop={30}
                />
            </TouchableOpacity>

            <CursiveText
                text={"Via Salomoni, 119, 17012 Albisola Marina SV, Italia"}
                fontSize={20}
                color={CustomColors.DARK_BLUE}
                marginBottom={30}
            />

            <ReceptionVenuePictures />

            <CursiveText
                text={"Inizio ricevimento: ore 17:00"}
                fontSize={30}
                color={CustomColors.DARK_BLUE}
                fontFamily={"SnellBT-Regular"}
            />

            <MapToGetThere
                mapURI={require('../assets/images/VillaFaraggianaMap.png')}
                linkToGoogleMaps={'https://maps.app.goo.gl/WH19u7F3DNpoMAmR6'}
            />


        </View>
    )
}

const ReceptionVenuePictures = (props) => {
    const { pictures } = props;

    const { width, height } = Dimensions.get('window');

    let singlePictureWidth = width * 0.3
    let singlePictureHeight = singlePictureWidth;

    if (width > 1000) { // Big screen
        singlePictureWidth = width * 0.2
        singlePictureHeight = singlePictureWidth;
    }

    return (
        <View style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,

            flexWrap: "wrap",
        }}>
            <SinglePicture
                width={singlePictureWidth}
                height={singlePictureHeight}
                link={'https://www.villafaraggiana.it/'}
                pictureURI={require('../assets/images/VillaFaraggiana1.png')}
            />

            <SinglePicture
                width={singlePictureWidth}
                height={singlePictureHeight}
                link={'https://www.villafaraggiana.it/'}
                pictureURI={require('../assets/images/VillaFaraggiana2.png')}
            />

            <SinglePicture
                width={singlePictureWidth}
                height={singlePictureHeight}
                link={'https://www.villafaraggiana.it/'}
                pictureURI={require('../assets/images/VillaFaraggiana3.png')}
            />

        </View>
    )
}

export default TheReception;
