

const timeUntil = (thisDate) => {

    const untilYear = thisDate['year'];
    const untilMonth = thisDate['month'];
    const untilDay = thisDate['day'];

    const untilDateAsString = untilMonth + "/" + untilDay + "/" + untilYear

    var today = new Date();
    var untilThisDate = new Date(untilDateAsString);

    var difference_InMillis = untilThisDate.getTime() - today.getTime();

    var difference_InDays = difference_InMillis / (1000 * 3600 * 24);
    var difference_InHours = (difference_InDays - Math.floor(difference_InDays)) * 24;
    var difference_InMinutes = (difference_InHours - Math.floor(difference_InHours)) * 60;


    difference_InDays = Math.floor(difference_InDays)
    difference_InHours = Math.floor(difference_InHours)
    difference_InMinutes = Math.floor(difference_InMinutes)

    // console.log("difference_InDays " + difference_InDays);
    // console.log("difference_InHours " + difference_InHours);
    // console.log("difference_InMinutes " + difference_InMinutes);

    return {
        daysLeft: difference_InDays,
        hoursLeft: difference_InHours,
        minutesLeft: difference_InMinutes
    };
}

export default timeUntil;