
class CustomColors {

    static DARK_BLUE = "#274254";
    static LIGHT_BLUE = "#77BCE9";
    static VERY_LIGHT_BLUE = "#E4F5FF";
    static PALE_WHITE = "#FFFAFA";
    static BLACK = "black";
    static DARK_RED = "#8B0000";
    static GREY = "#AAA";
}

export default CustomColors;