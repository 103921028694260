import { View } from "react-native";

import CursiveText from "../../Components/CursiveText";
import CustomColors from "../../commons/Colors";
import TwoLinesWithIconSeparator from "../../Components/TwoLinesWithIconSeparator";
import { Outlet, Link } from "react-router-dom";

const OurIbanDetails = () => {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: CustomColors.DARK_BLUE,
      }}
    >
      <TwoLinesWithIconSeparator
        backgroundColor={CustomColors.PALE_WHITE}
        iconURI={require("../../assets/images/money.png")}
        marginTop={40}
      />

      <IBAN />
    </View>
  );
};

const IBAN = () => {
  return (
    <View
      style={{
        width: "100%",
        alignItems: "center",
        padding: 10,
        // backgroundColor: "green"
        // marginTop: 40,
      }}
    >
      <CursiveText
        text={"IBAN"}
        fontSize={25}
        fontWeight={"bold"}
        marginBottom={50}
        color={CustomColors.PALE_WHITE}
      />

      <CursiveText
        text={"IT9334883480000000123"}
        fontSize={20}
        marginBottom={50}
        color={CustomColors.PALE_WHITE}
      />

      <CursiveText
        text={"CAUSALE"}
        fontSize={25}
        fontWeight={"bold"}
        marginBottom={50}
        color={CustomColors.PALE_WHITE}
      />

      <CursiveText
        text={"Regalo Matrimonio Elisabetta&Andrea"}
        fontSize={20}
        marginBottom={50}
        color={CustomColors.PALE_WHITE}
      />

      <CursiveText
        text={"INTESTATO A"}
        fontSize={25}
        fontWeight={"bold"}
        marginBottom={50}
        color={CustomColors.PALE_WHITE}
      />

      <CursiveText
        text={"Andrea Sciarrone, Elisabetta Guida"}
        fontSize={20}
        color={CustomColors.PALE_WHITE}
      />

      <Back />
    </View>
  );
};

const Back = () => {
  return (
    <Link
    to="/viaggio"
    style={{
        marginTop: 20,
      borderRadius: 30,
      padding: 15,
      backgroundColor: CustomColors.GREY
    }}
  >
    <CursiveText
      text={"Indietro"}
      fontSize={20}
      fontWeight={"bold"}
      color={CustomColors.DARK_BLUE}
    />
  </Link>
  );
};

export default OurIbanDetails;
