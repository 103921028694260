import { useEffect } from "react"
import { View, StyleSheet, Image, Text, Pressable, Animated } from "react-native"
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

import CustomColors from "../commons/Colors";


const SendEmailConfirmationBox = (props) => {
    const { confirmationEmailAck, setConfirmationEmailAck } = props;
    const { showSendEmailConfirmationBox, setShowSendEmailConfirmationBox } = props;

    return (
        showSendEmailConfirmationBox &&
        <View style={style.main}>
            <Confirmation
                confirmationEmailAck={confirmationEmailAck}
                text={"Grazie !\n\nTi confermiamo di aver ricevuto il tuo messaggio.\n\nCi vediamo il 13 Luglio!\n\nAndrea & Elisabetta"}
                setShowSendEmailConfirmationBox={setShowSendEmailConfirmationBox}
                setConfirmationEmailAck={setConfirmationEmailAck}
            />
        </View>
    )
}

const Confirmation = (props) => {
    const { confirmationEmailAck, text, setShowSendEmailConfirmationBox, setConfirmationEmailAck } = props;

    let rotate = new Animated.Value(0);
    let rotateAnimation = rotate.interpolate({
        inputRange: [0, 1],
        outputRange: ["0deg", "360deg"],
        extrapolate: "clamp",
    });


    Animated.loop(
        Animated.timing(rotate, {
            toValue: 1,
            duration: 2000,
            useNativeDriver: false, // con true non funziona il loop
        })
    ).start();


    if (!confirmationEmailAck) {
        return (
            <View style={{ width: "100%", height: "100%", alignContent: "center", justifyContent: "center" }}>
                <View style={style.iconView}>
                    <Animated.Image style={{
                        width: "75%", height: "75%", alignSelf: "center",
                        transform: [{ rotate: rotateAnimation }],
                    }}
                        source={require('../assets/images/barbell.png')}
                        resizeMode="contain"
                    />
                </View>
            </View>
        )
    }

    return (
        <View style={{ width: "100%", height: "100%", }}>
            <Icon icon={require('../assets/images/cat.png')} />
            <ConfirmationText text={text} />
            <OkButton
                setShowSendEmailConfirmationBox={setShowSendEmailConfirmationBox}
                setConfirmationEmailAck={setConfirmationEmailAck} />
        </View>
    )
}







const Icon = (props) => {
    const { icon } = props;
    return (
        <View style={style.iconView}>
            <Animated.Image style={style.icon}
                source={icon}
                resizeMode="contain"

            />
        </View>
    )
}

const ConfirmationText = (props) => {
    const { text } = props;
    return (
        <View style={style.textView}>
            <Text style={style.text}>{text}</Text>
        </View>
    )
}

const OkButton = (props) => {
    const { setShowSendEmailConfirmationBox, setConfirmationEmailAck } = props;

    return (
        <View style={style.buttonView}>
            <Pressable style={style.button}
                onPress={() => {
                    setShowSendEmailConfirmationBox(false);
                    setConfirmationEmailAck(false);
                }}>

                <View style={{ width: "100%", flexDirection: "row" }}>

                    <View style={{ flex: 2, alignItems: "center", justifyContent: "center" }}>
                        <Text style={{
                            fontSize: RFValue(14),
                            fontWeight: "bold",
                        }}>Ok</Text>
                    </View>

                    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                        <Image style={{ width: "35px", height: "35px", alignSelf: "center", }}
                            source={require('../assets/images/cat_paw.png')}
                            resizeMode="contain" />
                    </View>

                </View>
            </Pressable >
        </View >
    )
}


const style = StyleSheet.create({
    main: {
        width: "20%", height: "80%",
        borderWidth: 5, borderColor: "black",

        position: "absolute",
        alignSelf: "center",
        marginTop: "2%",

        shadowColor: '#FFF',
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.7,
        shadowRadius: 2,
        elevation: 5,

        alignItems: "center",
        justifyContent: "space-evenly",

        backgroundColor: CustomColors.GREY,

        zIndex: 1
    },

    iconView: {
        width: "100%", height: "25%",
        alignContent: "center", justifyContent: "center",
        // backgroundColor: "red"
    },

    icon: {
        width: "75%", height: "75%", alignSelf: "center",
    },

    textView: {
        width: "100%", height: "65%",
        alignContent: "center", justifyContent: "center",

        // backgroundColor: "green"

    },

    text: {
        fontSize: "28px",
        textAlign: "center"
    },


    buttonView: {
        width: "100%", height: "10%",
        alignContent: "center", justifyContent: "center",

        // backgroundColor: "purple"

    },

    button: {
        width: "50%",
        height: "95%",
        alignItems: "center", justifyContent: "center",
        alignSelf: "center",
        borderColor: CustomColors.DARK_BLUE,
        borderWidth: 2,
        borderStyle: "solid",
        borderRadius: 10,

        shadowColor: '#FFF',
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.7,
        shadowRadius: 2,
        elevation: 5,

        marginBottom: "5px",

        backgroundColor: CustomColors.PALE_WHITE,
    },

});

export default SendEmailConfirmationBox;